<template>
  <div class="c-app modal-vue reports miner-info statistics">
    <CWrapper class="bg-bg-gray">
      <div class="breadcrumb">
        <router-link to="Reports">Reports</router-link>/ Statistics
      </div>
      <div class="d-flex justify-content-between align-items-start">
        <div class="c-title pb-4">{{ $t("Statistics") }}</div>
        <div>
          <CButton @click="generatePDF" class="button-white w-auto h-auto rounded px-4"
            >PDF</CButton
          >
          <CButton
            class="button-blue w-auto ml-3 h-auto xls-hover px-4"
            @click="downloadExcel()"
            >XLS</CButton
          >
        </div>
      </div>
      <form action="" class="mb-3">
        <CRow>
          <CCol md="3" class="mb-3 mb-lg-3">
            <div class="position-relative farm-select">
              <CCard
                @click="collapseItem('collapse1')"
                class="myCard w-100 farmCard shadow-none m-0 p-2 text-black d-flex flex-row justify-content-between align-items-center cursor-pointer"
              >
                <span class="text-black targetCardtext">{{ $t("Farm") }}</span>
                <img
                  class="border p-1 targetCardtext"
                  src="@/assets/icons/arrow-select.svg"
                  alt=""
                />
              </CCard>
              <CCollapse
                :show="collapseItems['collapse1']"
                class="collapseCard w-100 border-0 position-absolute"
              >
                <CCard class="farmCard2 w-100 shadow-none p-2 mt-0 mb-0 radio_card">
                  <p
                    class="d-flex align-items-center relative text-main_black"
                    v-for="(obj, i) in [filteredItems.farms]"
                    :key="i"
                  >
                    <input
                      type="checkbox"
                      :id="'h' + i"
                      name="farms"
                      class="d-inline w-auto targetCardtext"
                      :value="i"
                      disabled
                    />
                    <!--                      <span class="checkmark"></span>-->
                    <label :for="'h' + i" class="w-100 mb-0 ml-3 targetCardtext">{{
                      obj
                    }}</label>
                  </p>
                </CCard>
              </CCollapse>
            </div>
          </CCol>
          <CCol md="3" class="mb-3 mb-lg-3">
            <div class="position-relative farm-select">
              <CCard
                @click="collapseItem('collapse2')"
                class="myCard w-100 farmCard shadow-none m-0 p-2 text-black d-flex flex-row justify-content-between align-items-center cursor-pointer"
              >
                <span class="targetCardtext text-black">{{ $t("Container") }}</span>
                <img
                  class="border targetCardtext p-1"
                  src="@/assets/icons/arrow-select.svg"
                  alt=""
                />
              </CCard>
              <CCollapse
                :show="collapseItems['collapse2']"
                class="collapseCard w-100 border-0 position-absolute"
              >
                <CCard class="farmCard2 w-100 shadow-none p-2 mt-0 mb-0 radio_card">
                  <p
                    class="d-flex align-items-center relative text-main_black"
                    v-for="(obj, i) in filteredItems.containers"
                    :key="i"
                  >
                    <input
                      type="checkbox"
                      :id="'s' + i"
                      name="farms"
                      class="d-inline w-auto targetCardtext"
                      :value="i"
                      readonly
                      disabled
                    />
                    <!--                      <span class="checkmark"></span>-->
                    <label :for="'s' + i" class="w-100 mb-0 ml-3 targetCardtext">{{
                      obj
                    }}</label>
                  </p>
                </CCard>
              </CCollapse>
            </div>
          </CCol>
          <CCol md="3" class="mb-3 mb-lg-3">
            <div class="position-relative farm-select">
              <CCard
                @click="collapseItem('collapse3')"
                class="myCard w-100 farmCard shadow-none m-0 p-2 text-black d-flex flex-row justify-content-between align-items-center cursor-pointer"
              >
                <span class="targetCardtext text-black">{{ $t("Miners") }}</span>
                <img
                  class="targetCardtext border p-1"
                  src="@/assets/icons/arrow-select.svg"
                  alt=""
                />
              </CCard>
              <CCollapse
                :show="collapseItems['collapse3']"
                class="collapseCard w-100 border-0 position-absolute"
              >
                <CCard
                  class="farmCard2 w-100 shadow-none p-2 mt-0 mb-0 radio_card"
                  @scroll="onScrollMiners"
                >
                  <p
                    class="d-flex align-items-center relative text-main_black"
                    v-for="(obj, i) in filteredItems.minersNames"
                    :key="i"
                  >
                    <input
                      type="checkbox"
                      :id="'j' + i"
                      :checked="checkers['miners']"
                      name="miners"
                      disabled
                      class="d-inline w-auto targetCardtext"
                      :value="i"
                    />
                    <!--                      <span class="checkmark"></span>-->
                    <label :for="'j' + i" class="w-100 mb-0 ml-3 targetCardtext">{{
                      obj
                    }}</label>
                  </p>
                </CCard>
              </CCollapse>
            </div>
          </CCol>
        </CRow>
      </form>
      <CSpinner color="main_black" grow class="m-auto" v-if="loading" />
      <div id="grafics" class="mobile-statistics d-flex flex-column-reverse" v-else>
        <CChartLine
          class="bg-white statistics-charts"
          style="height: 675px; border-radius: 16px; padding: 32px"
          :datasets="[
            {
              data: changeChart.firstVal
                ? changeChart.firstVal
                : getter.statisticsContent.map((res) => res.current_hashrate),
              borderColor: '#EF3B50',
              backgroundColor: 'transparent',
              label: 'Current',
            },
            {
              data: changeChart.secondVal
                ? changeChart.secondVal
                : getter.statisticsContent.map((res) => res.exp_hashrate),
              borderColor: '#04B77E',
              backgroundColor: 'transparent',
              label: 'Expected',
            },
          ]"
          :labels="getter.statisticsContent.map((res) => res.new_date)"
          :options="getCustomChart('xAxis', 'PH/S')"
        />
        <CTabs
          variant="pills"
          :active-tab="0"
          class="statistics-tabs"
          @update:activeTab="change"
        >
          <CTab title="">
            <template #title="{ item }">
              {{ $t("Average Hashrate") }}
              <span>{{
                getter.statisticsBrief ? getter.statisticsBrief.hashrate : 0
              }}</span>
            </template>
          </CTab>
          <CTab title="">
            <template #title="{ item }">
              {{ $t("Average Temperature") }}
              <span>{{
                getter.statisticsBrief ? getter.statisticsBrief.temperature : 0
              }}</span>
            </template>
          </CTab>
          <CTab title="">
            <template #title="{ item }">
              {{ $t("Efficiency") }}
              <span
                >{{
                  getter.statisticsBrief ? getter.statisticsBrief.efficiency : 0
                }}%</span
              >
            </template>
          </CTab>
        </CTabs>
      </div>
    </CWrapper>
  </div>
</template>

<script>
import { storeParent } from "../../api/composition/filtering";
import { computed, reactive, ref } from "@vue/composition-api";
import axios from "axios";
import fileDownload from "js-file-download";

import jsPDF from "jspdf";

export default {
  name: "Statistics",
  components: {},
  data() {
    return {
      route: this.$route,
      optionsChart: {
        scales: {
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
              scaleLabel: {
                display: true,
                labelString: "Hour",
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      document.querySelector("body").addEventListener("click", (e) => {
        if (
          e.target.type !== "checkbox" &&
          !e.target.className.includes("farmCard2") &&
          !e.target.className.includes("collapseCard") &&
          !e.target.className.includes("myCard") &&
          !e.target.className.includes("farm-select") &&
          !e.target.className.includes("targetCardtext")
        ) {
          this.collapseItems.collapse1 = false;
          this.collapseItems.collapse2 = false;
          this.collapseItems.collapse3 = false;
        }
      });
    });
  },
  setup(props, { root }) {
    const _store = storeParent(root);
    const collapseItems = reactive({
      collapse1: false,
      collapse2: false,
      collapse3: false,
    });
    const loading = ref(true);
    const _dispatch = _store.dispatchComposition();
    const helpers = _store.helpers();
    const date = reactive({
      from: "2021-10-10",
      to: "2021-10-14",
    });
    const miners = reactive({ items: [] });
    const changeChart = reactive({
      firstVal: null,
      secondVal: null,
    });
    const filteredItems = reactive({
      farms: root.$route.query.farmName,
      containers: root.$route.query.containerName,
      minersNames: root.$route.query.miners,
    })
    const filterFields = reactive({
      farms: "",
      farmsId: "1",
      containers: "",
      dateBegin: "2021-10-10",
      dateEnd: "2021-10-14",
    });
    const scrolls = ref(1);
    const filterCachings = reactive({
      cache: {},
      farm: {},
      container: {},
      miner: {},
    });
    const choosenContainers = ref(null);
    const checkers = reactive({
      miners: null,
      farms: null,
      containers: null,
    });
    // eslint-disable-next-line no-unused-vars
    const changeValue = (value, index, target) => {
      
    };
    const changeFarm = (value, index, cache) => {
      return Object.keys(cache)
        .map((item) => {
          return cache[item];
        })
        .toString();
    };

    const filterItems = () => {
      Object.keys(collapseItems).forEach((res) => {
        collapseItems[res] = false;
      });
      loading.value = true;
      _dispatch
        .getStatistics({
          ...filterFields,
          [filterFields["field"] + "s"]: filterFields.data,
        })
        .then(() => {
          loading.value = false;
        });
    };

    const change = (e) => {
      let firstValue,
        secondValue = "";
      switch (e) {
        case 0:
          firstValue = "current_hashrate";
          secondValue = "exp_hashrate";
          break;
        case 1:
          firstValue = "current_temperature";
          secondValue = "exp_temperature";
          break;
        case 2:
          firstValue = "efficiency";
          secondValue = null;
          break;
      }
      changeChart.firstVal = getter.value.statisticsContent.map((res) => res[firstValue]);
      changeChart.secondVal = getter.value.statisticsContent.map((res) =>
        secondValue ? res[secondValue] : null
      );
    };

    const downloadPdf = () => _dispatch.downloadPdf(filterFields);
    const downloadExcel = () => {
      axios({
        method: "POST",
        url: process.env.VUE_APP_API + "/api/reports/xls",
        data: { [filterFields.field + "s"]: filterFields.data, ...date },
        headers: {
          Authorization: `Bearer ` + window.localStorage.getItem("btsminer_token"),
        },
        responseType: "blob",
      }).then((res) => {
        fileDownload(res.data, "report.xlsx");
      });
    };
    if (root.$route.query.farms || root.$route.query.containers || root.$route.query.miners) {
      _dispatch
        .getStatistics({
          ...root.$route.query,
          reportByMonth: true,
        })
        .then(() => {
          loading.value = false;
        });
    } 
    else {
      _dispatch.getStatistics().then(() => {
        loading.value = false;
      });
    }

    const getters = computed(() => _store.gettersComposition());

    const getter = computed(() => _store.getter());
    const collapseItem = (name) => {
      collapseItems[name] = !collapseItems[name];
      Object.keys(collapseItems).forEach((res) => {
        if (res != name) {
          collapseItems[res] = false;
        }
      });
    };

    const onScrollMiners = (e) => {
      if (getters.value.miners.length === 0) return;
      const element = e.target;
      if (element.scrollHeight - element.scrollTop <= element.clientHeight + 2) {
        scrolls.value += 1;
        if (choosenContainers)
          _dispatch.getMiners({
            page: scrolls.value,
            body: choosenContainers.value,
          });
        else _dispatch.getMiners(scrolls.value);

        miners.items = [...miners.items, ...getters.value.miners];
      }
    };

    return {
      getters,
      loading,
      date,
      changeFarm,
      onScrollMiners,
      getter,
      downloadPdf,
      filteredItems,
      change,
      changeValue,
      collapseItem,
      collapseItems,
      miners,
      changeChart,
      filterItems,
      downloadExcel,
      checkers,
    };
  },
  created() {
    this.curPage();
  },
  methods: {
    curPage() {
      this.$emit("curPage", "Statistics");
    },
    getCustomChart(xName, yName) {
      return {
        scales: {
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
              scaleLabel: {
                display: true,
                labelString: xName,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
              scaleLabel: {
                display: true,
                labelString: yName,
              },
            },
          ],
        },
      };
    },
    generatePDF() {
      const doc = new jsPDF("l", "pt", "a4");
      doc.html(document.querySelector("#grafics"), {
        callback: function (pdf) {
          pdf.save("statistics.pdf");
        },
      });
    },
  },
};
</script>

<style scoped lang="sass">
canvas
  background-color: white
</style>
